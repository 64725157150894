import * as standard from './sources/standard'
import {
  organizeDataSource,
  createFsDataRunnerBySource,
} from '@shein-aidc/utils-data-source-toolkit'
import type { DS_FooterSheinInfo } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_FooterSheinInfo.SourceParams): DS_FooterSheinInfo.APIS {
  return organizeDataSource<DS_FooterSheinInfo.APIS, DS_FooterSheinInfo.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_FooterSheinInfo.FS_SourceParams) {
  const { footerInfoFetch } = getSource(sourceParams)
  return createFsDataRunnerBySource<DS_FooterSheinInfo.FS_APIS, DS_FooterSheinInfo.FS_DATA>({
    footerInfoFetch,
  })
}
